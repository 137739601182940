html,
body,
#root,
#root > div {
  height: 100%;
}

.app {
  background-color: #33333d;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 30px;
}

.header-icon {
  color: #04b97f;
  margin-right: 30px;
  display: flex;
}

.header-text {
  color: rgba(4, 185, 127, 0.69);
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 20px;
}

.main {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  color: #a1a1ab;
  font-size: 20px;
}

.devices {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.device {
  background-color: #04b97f;
  display: flex;
  flex-direction: row;
  width: 40%;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  padding: 16px 32px;
  gap: 30px;
}

.device-name {
  color: #33333d;
  font-size: 20px;
  font-weight: 600;
}

.device-icon {
  color: #33333d;
}

.bottom {
  margin-top: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.bottom-icon {
  color: #04b97f;
}

.search-text {
  color: rgba(4, 185, 127, 0.69);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 40px;
}

.notify-text {
  color: #a1a1ab;
  font-weight: 400;
  text-align: center;
}

/*----------------------------------- */

.connected-indicator {
  color: #04b97f;
  display: flex;
  margin-top: auto;
  justify-content: center;
  font-weight: 700;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.connected-text {
  display: flex;
  gap: 6px;
  color: rgba(4, 185, 127, 0.69);
}

.player {
  margin-top: auto;
  display: flex;
  background-color: #04b97f;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.volume-indicator {
  color: #33333d;
  display: flex;
  align-items: center;
  flex: 90%;
}

.control {
  display: flex;
  color: #33333d;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #33333d;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-left: 20px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-ms-fill-lower {
  background: #04aa6d;
}

.slider::-ms-fill-upper {
  background: #33333d;
}

.stats {
  display: flex;
  flex-direction: column;
  color: #a1a1ab;
  align-items: center;
}

/* .stat::before {
  content: '';
  height: 100%;
  width: 8px;
  border-radius: 10px;
  background: #04aa6d;
  display: block;
  margin-right: 10px;
} */

.stat {
  font-size: 40px;
  margin-top: 80px;
  display: flex;
  color: rgba(4, 185, 127, 0.69);
  padding: 12px 20px;
  width: 80%;
  justify-content: center;
}

.stat span {
  padding: 10px;
  font-size: 20px;
}

.stat strong {
  color: rgba(4, 185, 127, 0.69);
  margin-left: 10px;
}

.screen-lock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #04aa6d;
}

.screen-lock button {
  padding: 10px 12px;
  background-color: #04b97f;
  outline: none;
  border: none;
  cursor: pointer;
  color: #33333d;
  font-weight: 600;
  margin-top: 20px;
  border-radius: 20px;
}
