body {
  margin: 0;
  font-family: 'Google Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhWdRFD48TE63OOYKtrwEIKlh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RFD48TE63OOYKtrwEIKlh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhWdRFD48TE63OOYKtrw2IKlh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RFD48TE63OOYKtrw2IKlh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhWdRFD48TE63OOYKtrzaJ6lh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RFD48TE63OOYKtrzaJ6lh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhWdRFD48TE63OOYKtrzjJ6lh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RFD48TE63OOYKtrzjJ6lh.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3G8teXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GctaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GrNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3G8taXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GwNaXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GLNGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cPrjdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnufC_ar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnv_C_ar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnvfC_ar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnsvCPar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhzZU5Ak9u-oMExPeInvcuEmPosC9zyteYEFU68cvofdKM1XLPTxlGmzczpgWvF1SbxVhQidBnt_CPar1J9g0sLBUv3GFdGXng.woff)
    format('woff');
}
.google-symbols {
  font-family: 'Google Symbols';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3ewp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Kwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Pwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Rwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0589, U+10A0-10FF, U+2D00-2D2F;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Nwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3twp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0964-0965, U+0A01-0A75, U+200C-200D, U+20B9, U+25CC, U+262C,
    U+A830-A839;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Mwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Hwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0E81-0EDF, U+25CC;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Ywp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3awp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Bwp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Awp5eKQtGBlc.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesans/v45/4UaGrENHsxJlGDuGo1OIlL3Owp5eKQtG.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Google Symbols';
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/googlesymbols/v148/HhyNU5Ak9u-oMExPeInvcuEmPpEM1fI93A.woff2)
    format('woff2');
}
.google-symbols {
  font-family: 'Google Symbols';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
